<template>
  <div class="sub-header">
    <div class="sub-header__wrap container" style="display: flex;">
      <h1 class="sub-header__title main-title">Маршруты</h1>
      <button :disabled="update" @click="update1C" :class="{'update_active': !update, 'update_passive': update}" style="left: 100%; position: sticky;">
        Обновить с 1С
      </button>
    </div>
  </div>
</template>

<script>
export default {
  data(){
    return {
      update: false,
    }
  },
  methods: {
    async update1C()
    {
      this.update = true;

      await this.$api
        .post('/web/update_from_1c')
        .then(() => {
          this.update = false;
          this.$store.dispatch("getOrdersActiveProducts");
          this.$toast.success('Успешно обновили с 1С');
        })
        .catch((e) => {
          this.update = false;
          this.$toast.error(e.response.data.message);
        });
    }
  }
};
</script>

<style scoped>
.sub-header {
  padding: 16px 0;
  background-color: #fff;
  border: 1px solid #f2f2f8;
  box-sizing: border-box;
  height: 80px;
}
.update_active {
  padding: 0 16px;
  min-width: 51px;
  height: 36px;
  font-style: normal;
  text-align: center;
  background: var(--secondary-color-light);
  color: var(--secondary-color);
  box-shadow: 0px 2px 4px rgba(37, 183, 67, 0.12);
  border-radius: 16px;
  font-family: "Product Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
.update_passive {
  padding: 0 16px;
  min-width: 51px;
  height: 36px;
  font-style: normal;
  text-align: center;
  background: gray;
  color: black;
  box-shadow: 0px 2px 4px rgba(37, 183, 67, 0.12);
  border-radius: 16px;
  font-family: "Product Sans", sans-serif;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
}
</style>
